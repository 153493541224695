import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import randomPost1 from "../../../assets/images/posts/random-post1.jpg";
import randomPost2 from "../../../assets/images/posts/random-post2.jpg";
import randomPost3 from "../../../assets/images/posts/random-post3.jpg";

import { FaFacebookF, FaTelegramPlane } from "react-icons/fa";
import { BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs";
import { BiRss, BiChevronRight } from "react-icons/bi";


function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-md-0 mb-4">
              <div className="brandLogo">
                <Link to="/">
                  <img src={logo} alt="News" className="img-fluid" />
                </Link>
                <p>
                  Prerna Media Group is Urban India's #1 newspaper group and has
                  5 newspapers with 61 editions. The group has a readership of
                  6.63 crores (Source: TR | IRS 19 Q4, U+R).
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-md-0 mb-4">
              <h4 className="footer-heading  footerTitle">
                <span className="icon  footerTitle justify-content-center">
                  <i className="ion-ios-list-box" />
                </span>
                Random Posts
              </h4>
              <div className="block-21">
                <div className="text randomPost">
                  <div className="postFigure">
                    <figure>
                      <img src={randomPost1} alt="Post" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="postContent">
                    <h6 className="heading">
                      <Link to="/">
                        Sensex, Nifty Today: शेयर बाजार: आज फिर बढ़त के साथ
                        खुला...
                      </Link>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="block-21 ">
                <div className="text randomPost">
                  <div className="postFigure">
                    <figure>
                      <img src={randomPost2} alt="Post" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="postContent">
                    <h6 className="heading">
                      <Link to="/">
                        Adyar Cancer Institute की संस्थापक ने 93 साल की उम्र
                        में...
                      </Link>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="block-21 ">
                <div className="text randomPost">
                  <div className="postFigure">
                    <figure>
                      <img src={randomPost3} alt="Post" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="postContent">
                    <h6 className="heading">
                      <Link to="/">
                        रूसी राष्‍ट्रपति व्‍लादिमीर पुतिन ने दी धमकी,
                        दुश्‍मनों...
                      </Link>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-md-0 mb-4">
              <h4 className="footer-heading  footerTitle"> Links </h4>
              <ul className="footerList">
                <li>
                  <Link to="/terms-and-conditions">
                    <BiChevronRight /> Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy">
                    <BiChevronRight /> Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/about">
                    <BiChevronRight /> About Us
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    <BiChevronRight /> Contact Us
                  </Link>
                </li>
              </ul>
              <h4 className="footer-heading  footerTitle">social media</h4>
              <div className="footerSocial">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=100067744910100">
                      <FaFacebookF />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/citynewsindia">
                      <BsTwitter />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/citynews_india/">
                      <BsInstagram />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/city-newsindia-846828203">
                      <BsLinkedin />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.tumblr.com/citynewsindia">
                      <FaTelegramPlane />
                    </a>
                  </li>
                  <li>
                    <a href="https://prernamedia.com/rss-feeds">
                      <BiRss />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="subscribe">
                <em>Subscribe here to get interesting stuff and updates!</em>
                <form className="subscribeForm">
                  <input
                    type="text"
                    placeholder="example@gmail.com"
                    className="form-control"
                  />
                  <button type="button" className="btn subsBtn">
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="row mt-5 pt-4 pb-3 border-top">
            <div className="col-md-6 col-lg-8">
              <div className="copyright">
                Copyright © 2023{" "}
                <Link to="https://reactnews.prernamedia.com/" target="_blank">
                  prernamedia.in
                </Link>{" "}
                | All Rights Reserved. Powered By: Abaris Softech.
              </div>
            </div>
            <div className="col-md-6 col-lg-4 text-md-right">
              <div className="copyright text-end">
                by
                <Link
                  to="/terms-and-conditions"
                  className="display-inline ps-1"
                >
                  Terms & Condition
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
